import clsx from "clsx";
import { useEffect, useRef } from "react";
import { Category } from "services-hooks/types";
import styles from "./category-list.module.scss";
import Image from "next/image";
import MemoLogo from "../icons/Logo";
import ArrowRight from "../icons/ArrowRight";
import { useIntersection } from "react-use";
import { calculateCategoryScroll } from "app/utils/scroll";
import { useTranslate } from "app/hooks/lang";
import { useFeaturesContext } from "app/context/features.context";

export interface CategoryListProps {
  items: Category[];
  label: string;
  loading: boolean;
  onCategoryClick: (category: Category) => void;
  isPremium?: boolean;
  fetchNextPage?: VoidFunction;
  className?: string;
}
export const CategoryList: React.FC<CategoryListProps> = ({
  items,
  label,
  loading,
  onCategoryClick,
  isPremium = false,
  fetchNextPage = () => {},
  className = ''
}) => {
  const t = useTranslate();
  const elementsRef = useRef<HTMLDivElement[]>([]);
  const radioListRef = useRef<HTMLDivElement>(null);

  const loadMoreTriggerRef = useRef(null);
  const intersection = useIntersection(loadMoreTriggerRef, {
    rootMargin: "0px",
    threshold: 1,
  });
  useEffect(() => {
    if (intersection?.isIntersecting) {
      fetchNextPage();
    }
  }, [intersection]);
  return (
    <div className={className}>
      <h2 className={styles.header}>
        <span>
          {label}
          {isPremium && (
            <em className={styles.premium_label}>{t("premium.premium")}</em>
          )}
        </span>
      </h2>
      <div
        className={styles.category_list}
        data-navigate-row
        data-navigate-scrollable
        ref={radioListRef}
      >
        {loading ? (
          <>
            <div className={clsx(styles.category, styles.placeholder_item)} />
            <div className={clsx(styles.category, styles.placeholder_item)} />
            <div className={clsx(styles.category, styles.placeholder_item)} />
            <div className={clsx(styles.category, styles.placeholder_item)} />
            <div className={clsx(styles.category, styles.placeholder_item)} />
            <div className={clsx(styles.category, styles.placeholder_item)} />
          </>
        ) : (
          <>
            {items.map((category, index) => (
              <div
                className={styles.category}
                tabIndex={0}
                data-navigate-item
                key={`category-${index}`}
                onClick={() => onCategoryClick(category)}
                ref={(e) => {
                  if (e) {
                    elementsRef.current[index] = e;
                  }
                }}
              >
                <div className={styles.category_img}>
                  {category.logo ? (
                    <div className={styles.cover_wrapper}>
                      <Image
                        className={styles.cover}
                        key={`radio-station-${index}`}
                        src={category.logo}
                        width={160}
                        height={160}
                        layout="fill"
                        objectFit="cover"
                        alt=""
                        unoptimized
                      />
                    </div>
                  ) : (
                    <div className={styles.cover_empty}>
                      <MemoLogo />
                    </div>
                  )}
                </div>
                {/* <div className={styles.category_details}>
                  <h3 className={styles.category_name}>{category.name}</h3>
                  <div className={styles.category_description}>
                    {category.description}
                  </div>
                </div> */}
              </div>
            ))}
            <div ref={loadMoreTriggerRef} />
          </>
        )}
      </div>
    </div>
  );
};
