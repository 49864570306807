import type { NextPage } from "next";
import Head from "next/head";
import styles from "./index.module.scss";
import { StationsList } from "app/components/stations-list/stations-list.component";
import { useGetPillowCategoryPath } from "services-hooks/hooks";
import { Category, Category as CategoryType } from "services-hooks/types";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useGoBackHistory } from "app/hooks/go-back.hook";
import { useTranslate } from "app/hooks/lang";
import useMetaDescription from "app/utils/getMetaDescription";
import clsx from "clsx";
import { CategoryList } from "app/components/category-list/category-list.component";
import { CatalogPopup } from "app/components/catalog/catalog.component";
import { useIsMobileResolution } from "app/utils/is-mobile-resolution";
import { useCountryContext } from "app/context/country.context";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useMedia } from "react-use";
import { useRouter } from "next/router";

const Home: NextPage = () => {
  const t = useTranslate();
  const getMetaDescription = useMetaDescription();
  const isMobile = useIsMobileResolution();
  const { country } = useCountryContext();

  const { asPath } = useRouter();

  const latinAmerica = ["mx", "ar", "cl", "pe", "br", "ve", "co", "bo"];
  const [regionPermalinks, setRegionPermalinks] = useState([
    "categories/all_radio/country/all_radio",
    "categories/all_radio/country/north_america",
    "categories/all_radio/country/france_belgium",
    "categories/all_radio/country/spain",
    "categories/all_radio/country/united_kingdom",
    "categories/all_radio/country/italy",
    "categories/all_radio/country/germany",
    "categories/all_radio/country/latin_america",
  ]);

  const initialRegion = "categories/all_radio/country/all_radio";
  // const initialRegion = "categories/all_radio/country/germany";
  const [activeRegion, setActiveRegion] = useState<string>(() => {
    if (asPath.indexOf("#") !== -1) {
      const hashRegion = asPath.substring(asPath.indexOf("#") + 1);
      const newRegion = `categories/all_radio/country/${hashRegion}`;
      if (regionPermalinks.indexOf(newRegion) !== -1) {
        return newRegion;
      }
    }
    if (country.countryCode === "gb") {
      return "categories/all_radio/country/united_kingdom";
    }
    if (country.countryCode === "de") {
      return "categories/all_radio/country/germany";
    }
    if (country.countryCode === "it") {
      return "categories/all_radio/country/italy";
    }
    if (country.countryCode === "es") {
      return "categories/all_radio/country/spain";
    }
    if (country.countryCode === "fr" || country.countryCode === "be") {
      return "categories/all_radio/country/france_belgium";
    }
    if (country.countryCode === "us" || country.countryCode === "ca") {
      return "categories/all_radio/country/north_america";
    }
    if (latinAmerica.includes(country.countryCode)) {
      return "categories/all_radio/country/latin_america";
    }
    return initialRegion;
  });

  const [activeRegionIndex, setActiveRegionIndex] = useState(
    regionPermalinks.indexOf(activeRegion)
  );

  const {
    data,
    error,
    isLoading: stationsLoading,
    fetchNextPage: fetchNextPageStations,
  } = useGetPillowCategoryPath(activeRegion, { pageSize: 12 });

  const {
    data: byAllradioData,
    error: byAllradioError,
    isLoading: byAllradioLoading,
    fetchNextPage: fetchNextPageByAllradio,
  } = useGetPillowCategoryPath("categories/all_radio/country/all_radio", {
    pageSize: 12,
  });

  const { data: regionsData } = useGetPillowCategoryPath(
    "categories/all_radio/country",
    { pageSize: 100 }
  );
  const {
    data: genresData,
    isLoading: genresLoading,
    fetchNextPage: genresFetchNextPage,
  } = useGetPillowCategoryPath("categories/all_radio/music", { pageSize: 50 });

  const genres: Category[] = useMemo(() => {
    const pages = genresData?.pages.map(item => item.data.body!.content!) || [];
    let result: Category[] = [];
    pages.forEach(item => result = result.concat(...item))
    return result;
  }, [genresData]);

  useEffect(() => {
    if (regionsData?.pages[0].data?.body?.content?.length) {
      setRegionPermalinks(
        regionsData?.pages[0].data?.body?.content?.map(
          (item) => item.permalink || ""
        )
      );
      setActiveRegionIndex(regionPermalinks.indexOf(activeRegion));
    }
  }, [regionsData]);

  const sliderRef = useRef<Slider>(null);
  useEffect(() => {
    setActiveRegion(regionPermalinks[activeRegionIndex] || "");
    sliderRef.current?.slickGoTo(activeRegionIndex);

    if (typeof window !== "undefined" && regionPermalinks[activeRegionIndex]) {
      window.location.hash = regionPermalinks[activeRegionIndex].substring(
        regionPermalinks[activeRegionIndex].lastIndexOf("/") + 1
      );
    }
  }, [activeRegionIndex, sliderRef?.current]);

  setTimeout(() => {
    // load hack
    sliderRef.current?.slickGoTo(activeRegionIndex);
  }, 100);

  useEffect(() => {
    if (regionPermalinks?.length) {
      setActiveRegionIndex(regionPermalinks.indexOf(activeRegion));
    }
  }, [regionPermalinks]);

  const radioSelectionList =
    data?.pages.reduce((acc, current) => {
      if (current.data.body?.content) {
        acc = [...acc, ...current.data.body.content];
      }
      return acc;
    }, [] as CategoryType[]) || [];

  const byAllradioRadioSelectionList =
    byAllradioData?.pages.reduce((acc, current) => {
      if (current.data.body?.content) {
        acc = [...acc, ...current.data.body.content];
      }
      return acc;
    }, [] as CategoryType[]) || [];

  useGoBackHistory("INDEX");

  const onRegionClick = (permalink: string) => {
    if (permalink) {
      setActiveRegionIndex(regionPermalinks.indexOf(permalink));
    }
  };

  const showSlider = useMedia("(max-width: 1600px)");
  const slickSettings = {
    centerMode: true,
    arrows: false,
    centerPadding: "0",
    initialSlide: activeRegionIndex,
    slidesToShow: 1,
    variableWidth: true,
    infinite: false,
  };
  const onSwipe = (dir: string) => {
    if (dir === "left") {
      if (activeRegionIndex + 1 < regionPermalinks?.length) {
        setActiveRegionIndex(activeRegionIndex + 1);
      }
    } else if (dir === "right" && activeRegionIndex > 0) {
      setActiveRegionIndex(activeRegionIndex - 1);
    }
  };

  const onSlickItemCLick = (index: number) => {
    setActiveRegionIndex(index);
  };

  const [isCatalogVisible, setIsCatalogVisible] = useState(false);
  const [selectedGanrePermalink, setSelectedGanrePermalink] = useState("");
  const onCategoryClick = (ganre: CategoryType) => {
    if (ganre) {
      setSelectedGanrePermalink(ganre.permalink || "");
      setIsCatalogVisible(true);
    }
  };

  return (
    <div className={styles.container}>
      <Head>
        <title>ALL.RADIO</title>
        {getMetaDescription(t("homepage-meta-description"))}
        <link rel="icon" href="/favicon.ico" />
      </Head>

      {showSlider ? (
        <Slider
          {...slickSettings}
          onSwipe={onSwipe}
          initialSlide={activeRegionIndex}
          ref={sliderRef}
        >
          {regionsData?.pages[0].data?.body?.content?.map((region, index) => (
            <>
              {region.permalink && (
                <div
                  className={clsx(styles.pill, {
                    [styles.pill_active]: activeRegion === region.permalink,
                  })}
                  key={`region-slick-${region.permalink})`}
                  onClick={() => onSlickItemCLick(index)}
                >
                  {region.name}
                </div>
              )}
            </>
          ))}
        </Slider>
      ) : (
        <div className={styles.pills_wrapper}>
          <div className={styles.pills}>
            {regionsData?.pages[0].data?.body?.content?.map((region, index) => (
              <>
                {region.permalink && (
                  <div
                    onClick={() => onRegionClick(region.permalink || "")}
                    className={clsx(styles.pill, {
                      [styles.pill_active]: activeRegion === region.permalink,
                      [styles.pill_neighbour]:
                        index === activeRegionIndex + 1 ||
                        index === activeRegionIndex - 1,
                    })}
                    key={`region-${region.permalink})`}
                  >
                    {region.name}
                  </div>
                )}
              </>
            ))}
          </div>
        </div>
      )}

      {initialRegion !== activeRegion && (
        <StationsList
          label={"Popular radios"}
          items={radioSelectionList}
          loading={stationsLoading}
          className={styles.home_stations_wrapper}
          fetchNextPage={fetchNextPageStations}
          showDetailsOnClick
        />
      )}
      <StationsList
        label={"By ALL.RADIO"}
        items={byAllradioRadioSelectionList}
        loading={byAllradioLoading}
        className={styles.home_stations_wrapper}
        fetchNextPage={fetchNextPageByAllradio}
        showDetailsOnClick
      />

      <CategoryList
        items={genres || []}
        label={t("allradio.genres")}
        loading={genresLoading}
        onCategoryClick={(category) => onCategoryClick(category)}
        className={styles.home_categories_wrapper}
        fetchNextPage={genresFetchNextPage}
      />

      {isCatalogVisible && (
        <CatalogPopup
          onClose={() => setIsCatalogVisible(false)}
          genres={genres || []}
          selectedGanrePermalink={selectedGanrePermalink}
        />
      )}
    </div>
  );
};

export default Home;
