import { useArrowKeyNavigationOverlay } from "app/hooks/keyboard-navigation.hook";
import clsx from "clsx";
import { Portal } from "../ui/portal";
import styles from "./catalog.module.scss";
import useModalScroll from "app/hooks/modal-scroll.hook";
import { Category } from "services-hooks/types";
import { useGetPillowCategoryPath } from "services-hooks/hooks";
import { useEffect, useMemo, useRef, useState } from "react";
import { StationsListVertical } from "../stations-list-vertical/stations-list-vertical.component";
import MemoAngleDown from "../icons/AngleDown";
import { useIsMobileResolution } from "app/utils/is-mobile-resolution";
import ArrowRight from "../icons/ArrowRight";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

interface CatalogPopupProps {
  onClose: VoidFunction;
  moveUnderHeader?: boolean;
  genres: Category[];
  selectedGanrePermalink: string;
}

export const CatalogPopup: React.FC<CatalogPopupProps> = ({
  onClose,
  moveUnderHeader,
  genres,
  selectedGanrePermalink,
}) => {
  const wrapperRef = useArrowKeyNavigationOverlay(!moveUnderHeader);
  useModalScroll();
  const isMobile = useIsMobileResolution();

  const [activeGenrePermalink, setActiveGenrePermalink] = useState(
    selectedGanrePermalink || ""
  );

  const [activeRegionIndex, setActiveRegionIndex] = useState(0);

  const { data, error, isLoading, fetchNextPage } = useGetPillowCategoryPath(
    activeGenrePermalink,
    { pageSize: 200 }
  );

  const regionPermalinks = useMemo(
    () => genres.map((item) => item.permalink),
    [genres]
  );

  useEffect(() => {
    if (regionPermalinks?.length) {
      setActiveRegionIndex(regionPermalinks.indexOf(activeGenrePermalink));
    }
  }, [regionPermalinks]);
  useEffect(() => {
    if (regionPermalinks.length) {
      setActiveGenrePermalink(regionPermalinks[activeRegionIndex] || "");
      setTimeout(() => {
        if (scrollRef.current) {
          const activeElement = scrollRef.current.querySelector(`.${styles.active}`);
          activeElement?.scrollIntoView({behavior: 'smooth'})
        }
      }, 0);
    }
  }, [activeRegionIndex]);

  const scrollRef = useRef<HTMLDivElement>(null);
  const scrollUp = () => {
    if (scrollRef?.current) {
      scrollRef.current.scrollTop -= 300;
    }
  };
  const scrollDown = () => {
    if (scrollRef?.current) {
      scrollRef.current.scrollTop += 300;
    }
  };

  useEffect(() => {
    if (isMobile) {
      setTimeout(() => {
        if (typeof window !== "undefined") {
          const activeELement = window.document.querySelector(
            `.${styles.pill}.${styles.pill_active}`
          );
          activeELement?.scrollIntoView({
            inline: "center",
            block: "center",
            behavior: "smooth",
          });
        }
      });
    }
  }, [activeGenrePermalink]);

  const slickSettings = {
    centerMode: true,
    arrows: false,
    centerPadding: "0",
    initialSlide: activeRegionIndex,
    slidesToShow: 1,
    variableWidth: true,
    infinite: false,
  };

  const onSwipe = (dir: string) => {
    if (dir === "left") {
      if (activeRegionIndex + 1 < regionPermalinks.length) {
        setActiveRegionIndex(activeRegionIndex + 1);
      }
    } else if (dir === "right" && activeRegionIndex > 0) {
      setActiveRegionIndex(activeRegionIndex - 1);
    }
  };

  const onSlickItemCLick = (index: number) => {
    setActiveRegionIndex(index);
  }

  return (
    <Portal>
      <div
        className={clsx(styles.popup_wrapper, {
          [styles.under_header]: moveUnderHeader,
        })}
        ref={wrapperRef}
      >
        <div className={styles.content}>
          <div className={styles.pills_wrapper}>
            <Slider {...slickSettings} onSwipe={onSwipe}>
              {genres.map((genre, index) => (
                <>
                  {genre.permalink && (
                    <div
                      className={clsx(styles.pill, {
                        [styles.pill_active]:
                          activeGenrePermalink === genre.permalink,
                      })}
                      key={`genre-pill-slick-${genre.permalink})`}
                      onClick={() => onSlickItemCLick(index)}
                    >
                      {genre.name}
                    </div>
                  )}
                </>
              ))}
            </Slider>
          </div>

          <div className={styles.content_main}>
            <div className={styles.catalog_header}>
              <button
                onClick={() => onClose()}
                className={styles.catalog_close}
              >
                <MemoAngleDown />
              </button>
            </div>

            <div className={styles.genres_wrapper}>
              <div className={styles.genres_scroll_wrapper}>
                <div className={styles.genres_inner} ref={scrollRef}>
                  {genres.map((genre) => (
                    <div
                      className={clsx(styles.genre, {
                        [styles.active]:
                          activeGenrePermalink === genre.permalink,
                      })}
                      key={`genre-${genre.permalink}`}
                      onClick={() =>
                        setActiveGenrePermalink(genre.permalink || "")
                      }
                    >
                      {genre.name}
                    </div>
                  ))}
                </div>
                <div className={styles.buttons}>
                  <button
                    className={clsx(styles.arrow, styles.arrow_up)}
                    onClick={() => scrollUp()}
                  >
                    <ArrowRight />
                  </button>
                  <button className={styles.arrow} onClick={() => scrollDown()}>
                    <ArrowRight />
                  </button>
                </div>
              </div>
            </div>

            <div className={styles.stations_wrapper}>
              {data?.pages[0].data?.body?.content?.length && (
                <StationsListVertical
                  items={data?.pages[0].data?.body?.content}
                  loading={isLoading}
                  showDetailsOnClick
                  showStationNames
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Portal>
  );
};
