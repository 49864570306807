import { playerActions } from "app/store/slices/player";
import { useAppDispatch } from "app/store/store";
import clsx from "clsx";
import { Category } from "services-hooks/types";
import styles from "./stations-list-vertical.module.scss";
import { radioActions } from "../../store/slices/radio";
import { splitToChunks } from "app/utils/array";
import ArrowRight from "../icons/ArrowRight";
import { useRef, useState } from "react";
import { v4 } from "uuid";
import { usePlayerContext } from "app/context/player.context";
import { useGoToRadio } from "app/hooks/navigation.hook";
import { calculateRadioScroll } from "app/utils/scroll";
import { useIsMobileResolution } from "app/utils/is-mobile-resolution";
import { useFeaturesContext } from "app/context/features.context";
import { StationsColumn } from "./stations-column.component";

export interface StationsListVerticalProps {
  items: Category[];
  label?: string;
  loading: boolean;
  showDetailsOnClick?: boolean;
  className?: string;
  showStationNames?: boolean;
  fetchNextPage?: VoidFunction;
}

export const StationsListVertical: React.FC<StationsListVerticalProps> = ({
  items,
  label,
  loading,
  showDetailsOnClick = true,
  className,
  showStationNames = false,
  fetchNextPage = () => {},
}) => {
  const goToRadio = useGoToRadio();
  const [scrollSyncId] = useState(() => v4());
  const dispatch = useAppDispatch();
  const { arrowNavigationEnabled } = useFeaturesContext();
  const { setPlayerEnabled, setFullscreenPlayerInitialyOpen } =
    usePlayerContext();
  const radioListRef = useRef<HTMLDivElement>(null);
  const itemRows = items;
  const handleOnClick = (radioStation: Category, stationIndex: number) => {
    if (showDetailsOnClick) {
      goToRadio(`/${radioStation.permalink}`);
      dispatch(
        radioActions.selectRadio({
          section: items,
          activeIndex: items.indexOf(radioStation),
        })
      );
    } else {
      setFullscreenPlayerInitialyOpen(true);
      setPlayerEnabled(true);
      dispatch(
        playerActions.selectTrack({
          category: radioStation,
          podcast: null,
        })
      );
      dispatch(
        playerActions.setRadioQueue({
          items: items,
          activeIndex: items.indexOf(radioStation),
        })
      );
    }
  };

  const isMobile = useIsMobileResolution();

  const onForward = () => {
    radioListRef.current?.scrollTo({
      left: calculateRadioScroll(
        radioListRef.current.scrollLeft,
        false,
        isMobile
      ),
      behavior: "smooth",
    });
  };

  const onBackward = () => {
    radioListRef.current?.scrollTo({
      left: calculateRadioScroll(
        radioListRef.current.scrollLeft,
        true,
        isMobile
      ),
      behavior: "smooth",
    });
  };

  return (
    <div className={clsx(styles.wrapper, className)}>
      <div className={styles.header}>
        {<h2>{label}</h2>}
      </div>

      <div className={styles.radio_list} ref={radioListRef}>
        {loading ? (
          <div className={styles.row}>
            <div
              className={clsx(styles.radio_station, styles.placeholder_item)}
            />
            <div
              className={clsx(styles.radio_station, styles.placeholder_item)}
            />
            <div
              className={clsx(styles.radio_station, styles.placeholder_item)}
            />
            <div
              className={clsx(styles.radio_station, styles.placeholder_item)}
            />
            <div
              className={clsx(styles.radio_station, styles.placeholder_item)}
            />
            <div
              className={clsx(styles.radio_station, styles.placeholder_item)}
            />
            <div
              className={clsx(styles.radio_station, styles.placeholder_item)}
            />
            <div
              className={clsx(styles.radio_station, styles.placeholder_item)}
            />
          </div>
        ) : (

            <StationsColumn
              // key={`radio-stations-row-${rowIndex}`}
              items={items}
              scrollSyncId={scrollSyncId}
              handleOnClick={handleOnClick}
              showStationNames={showStationNames}
              fetchNextPage={fetchNextPage}
              // triggerFetchNextPage={rowIndex === 0}
            />

        )}
      </div>
    </div>
  );
};
