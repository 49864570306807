import clsx from "clsx";
import { Category } from "services-hooks/types";
import styles from "./stations-list-vertical.module.scss";
import Image from "next/image";
import MemoLogo from "../icons/Logo";
import { useRef, useEffect } from "react";
import { useIntersection } from "react-use";
import { useIsMobileResolution } from "app/utils/is-mobile-resolution";

interface StationsColumnProps {
  items: Category[];
  wrapped?: boolean;
  showStationNames?: boolean;
  handleOnClick: (radioStation: Category, index: number) => void;
  scrollSyncId?: string;
  navigationDisabled?: boolean;
  fetchNextPage?: VoidFunction;
  triggerFetchNextPage?: boolean;
}

export const StationsColumn: React.FC<StationsColumnProps> = ({
  items,
  wrapped,
  showStationNames = true,
  handleOnClick,
  scrollSyncId,
  navigationDisabled,
  fetchNextPage = () => {},
  triggerFetchNextPage,
}) => {
  const loadMoreTriggerRef = useRef(null);
  const intersection = useIntersection(loadMoreTriggerRef, {
    rootMargin: "0px",
    threshold: 1,
  });

  const isMobile = useIsMobileResolution();

  useEffect(() => {
    if (triggerFetchNextPage && intersection?.isIntersecting) {
      fetchNextPage();
    }
  }, [intersection, triggerFetchNextPage]);
  return (
    <div
      className={clsx(styles.row, styles.vertical, {
        [styles.wrapped]: wrapped,
      })}
      data-navigate-row={!navigationDisabled}
      data-navigate-scrollable
      data-navigate-scrollable-parent
      data-sync-scroll-position={scrollSyncId}
    >
      {items.map((radioStation, index) => (
        <div
          key={`station-row-${index}-${radioStation.permalink}`}
          data-navigate-row={!navigationDisabled}
          className={styles.radio_station_wrapper}
        >
          <div
            data-navigate-item={!navigationDisabled}
            tabIndex={0}
            className={clsx(styles.radio_station, {
              [styles.show_station_names]: showStationNames,
            })}
            onClick={() =>
              handleOnClick(radioStation, items.indexOf(radioStation))
            }
          >
            {radioStation?.logo || radioStation?.logo ? (
              <div className={styles.cover_wrapper}>
                <Image
                  className={styles.cover}
                  src={radioStation?.logo || radioStation?.logo || ""}
                  layout="fill"
                  objectFit="cover"
                  alt=""
                  unoptimized
                />
              </div>
            ) : (
              <div className={clsx(styles.cover, styles.no_image)}>
                <MemoLogo />
              </div>
            )}
            {showStationNames && (
              <div className={styles.station_title_wrapper}>
                <div className={styles.station_title}>{radioStation.name}</div>
                {radioStation.description && <div className={styles.station_subtitle}>{radioStation.description}</div>}
              </div>
            )}
          </div>
        </div>
      ))}
      {triggerFetchNextPage && <div ref={loadMoreTriggerRef} />}
    </div>
  );
};
